import React from 'react';
import FacebookIcon from './ico_face.svg';
import InstagramIcon from './ico_ig.svg';
import WhatsappIcon from './ico_whatsapp.svg';
import ReactGA from 'react-ga';

// function parseCSVToArray(txt) {

//   let filas = txt.split("\n");

//   var resultado = [];
//   var keys = [];

//   filas.forEach((fila, i) => {

//     var columnas = fila.split(";");

//     var obj = {}

//     columnas.forEach((element, index) => {

//       if (i === 0) {

//         keys.push(element);

//       } else {

//         obj[keys[index]] = element

//       }

//     });

//     resultado.push(obj)

//   });
//   resultado.shift();

//   return resultado;

// }

function initializeReactGA() {
  ReactGA.initialize('UA-27744179-44');
  ReactGA.pageview('/vitrinas-comunales/destacados/');
}



class Card extends React.Component {


  getURL(item) {



   if (item.URL.indexOf("@") != -1  ) {

      return { url:"mailto:"+item.URL, label: 'Contactar' };

    }


    if (item.URL !== "" && item.URL !== "NO"  ) {

      return { url:item.URL, label: 'Visitar'};

    }

    if (item.instagram !== "" && item.instagram !=="NO") {

      return { url:item.instagram, label: 'Visitar'};

    }

    if (item.facebook !== "" && item.facebook !== "NO") {

      return { url:item.facebook, label: 'Visitar'};;

    }

    if (item.whatsapp !== "" && item.whatsapp !== "NO" ) {

      return { url:`https://wa.me/${item.whatsapp}` , label: 'Contactar'} ;

    }

    



  }

  printFacebook(item) {


    if (item.facebook != "" && item.facebook.toUpperCase() != "NO") {

      return (<li><img className='icon' src={FacebookIcon} /><a href={item.facebook}>Facebook</a></li>)

    }

  }

  printInstagram(item) {

    if (item.instagram != "" && item.instagram.toUpperCase() != "NO" ) {
      return (<li><img className='icon' src={InstagramIcon} /><a href={item.instagram}>Instagram</a></li>)
    }
  }


  render() {

    let item = this.props.item;

    if (!item) {

      return;

    }


    return (


      <div class='card'>

        <figure>


          <img className='imagen' src={`imgs/${item.imagen}`}></img>
          <img className='logo-municipalidad' src={`imgs/${item.comuna == 'Lo Espejo' ? 'lo-espejo.png' : 'estacion-central.png'}`}></img>

        </figure>



        <h2>{item.titulo}</h2>
        <p>{item.texto}</p>
        <p>{item.direccion != "" ? `Dirección: ${item.direccion}` : ''}</p>

        <ul>


          {this.printInstagram(item)}

          {this.printFacebook(item)}

          <li><img className='icon' src={WhatsappIcon} /><a href={`https://wa.me/${item.whatsapp}`}>Whatsapp</a></li>

        </ul>
        <p><a className='boton' href={this.getURL(item).url} rel='nonoopener noreferrer' target='_blank'>{this.getURL(item).label}</a></p>


      </div>
    )
  }
}




class App extends React.Component {


  shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
  }


  constructor(props) {
    super(props);
    this.state = {
      data: [],
      estacionCentral: [],
      loEspejo: [],
      destacados: [],
      titulo:"Destacados"

    };
  }


  componentDidMount() {


    initializeReactGA()





    fetch("datos.json")
      .then(res => res.json())
      .then(
        (result) => {

          var estacionCentral = [];
          var loEspejo = [];
          var destacados = [];

          var data = [];

          console.log(result)

          result.forEach(element => {


            if (element.destacado ) {

              destacados.push(element);
            }

            if (element.comuna == "Estación Central") {

              estacionCentral.push(element);

            } else {

              loEspejo.push(element);

            }



          });

          this.shuffleArray(destacados)

          //data.push({label: "Lo Espejo", data:loEspejo, anchor:'Lo-Espejo' })
          //data.push({label: "Estación Central", data:estacionCentral, anchor:'Estacion-Central'})

          this.setState({ data:  destacados, loEspejo: loEspejo, estacionCentral: estacionCentral, destacados: destacados });


        },
        (error) => { }
      )
  }


  render() {

    const { data, loEspejo, estacionCentral, destacados, titulo } = this.state;


    return (

      <content>
        <nav>

          <ul>

            <li><a onClick={() => {

              ReactGA.pageview('/vitrinas-comunales/destacados/');


              this.setState({data: this.state.destacados, titulo:"Destacados" })

            }} href='/' className='boton'>Destacados</a></li>
            <li><a onClick={() => {
                
                ReactGA.pageview('/vitrinas-comunales/lo-espejo/');
                this.setState({data: this.state.loEspejo, titulo:"Lo Espejo" })


            }} href='#Lo-Espejo' className='boton'>Lo Espejo</a></li>
           
            <li>
              <a onClick={() => {
              
              ReactGA.pageview('/vitrinas-comunales/estacion-central/');
              this.setState({data: this.state.estacionCentral, titulo:"Estación Central" })

            }} href='#Estacion-Central' className='boton'>Estación Central</a></li>

          </ul>

        </nav>

        <div>
          {

            <div>
              <h1>{this.state.titulo}</h1>
              <section>

                {
                  this.state.data.map((item, index) => (


                    <Card key={`k${index}`} item={item}></Card>
                  ))
                }

              </section>
            </div>



          }
        </div>




      </content>



    );

  }
}

export default App;
